<template>
  <v-dialog
    :value="mostrar"
    max-width="500px"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="error"
        flat
        dense
        dark
      >
        <v-toolbar-title>
          Confirme!
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        Tem certeza que deseja deletar este item?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <btn-cancelar
          outlined
          @click="$emit('fechar')"
        />
        <v-btn
          color="error"
          :loading="carregando"
          outlined
          @click="$emit('confirmar')"
        >
          <v-icon
            left
            v-text="'mdi-delete'"
          />
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogDeletar',

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      carregando: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
